import React from 'react';
import './Footer.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './About.css';

function Footer() {
    AOS.init();

    // Número de telefone para o WhatsApp
    const phoneNumber = '+555186529937';

    // Mensagem pré-definida
    const mensagem = 'Olá, vim pelo Website e gostaria de mais informações.';

    // Codificando a mensagem para ser usada em um URL
    const mensagemCodificada = encodeURIComponent(mensagem);

    // Criando o link do WhatsApp com o número e a mensagem
    const whatsLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${mensagemCodificada}`;

    return(
        <section className="footer">
            <h2 data-aos='fade-up' data-aos-duration='1500'>NÃO FIQUE DE FORA DESSA <b>OPORTUNIDADE</b></h2>
            <h2 data-aos='fade-up' data-aos-duration='2200'>ENTRE EM CONTATO AINDA HOJE!</h2>

            <img data-aos='zoom-in' data-aos-duration='2500' src='./img/logo-char.png' alt="Logochar"></img>

            <div>
                <a data-aos='fade-left' data-aos-duration='2500' target='_blank' href='https://www.facebook.com/profile.php?id=100077728621515&mibextid=ZbWKwL'><img src='./img/icon_facebook.png' alt='icon Facebook'></img></a>
                <a data-aos='zoom-in' data-aos-duration='1500' target='_blank' href='https://www.instagram.com/haasstreaming?igsh=MXFwYXlvaHAzNmZxbg=='><img src='./img/icon_instagram.png' alt='icon Instagram'></img></a>
                <a data-aos='fade-right' data-aos-duration='2500' target='_blank' href={whatsLink}><img src='./img/icon_whatsapp.png' alt='icon WhatsApp'></img></a>
            </div>

            <h3>©️ COPYRIGHT HAAS STREAMING. TODOS OS DIREITOS RESERVADOS</h3>
            <h4>Desenvolvido por <b><a href='https://codeliny.com' target='_blank'>Code Liny</a></b></h4>
        </section>
    )
};

export default Footer;
