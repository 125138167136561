import './Hero.css';

function Hero() {
    return(
        <section className="hero" id='hero'>
            <img src='img/logoMarca.png' alt='Hass Streaming'></img>
            <div>
                <a href='https://haasstreaming.com'><button>ASSINAR</button></a>
                <a href='https://revendedor.haasstreaming.com'><button className='actived'>REVENDER</button></a>
            </div>
        </section>
    );
};

export default Hero;