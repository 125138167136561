import './Cards.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function Cards() {
    useEffect(() => {
        AOS.init();
    });

    const phoneNumber = '+555186529937';

    const commonDescription = [
        'Painel Híbrido Grátis',
        'Suporte Diário',
        'Grupo no Telegram',
        'IPTV E P2P',
        'Servidores CDN',
        'Sistema Anti/DDOS',
        '100 mil Conteúdos',
        'Créditos não expiram'
    ];
    
    const cardsData = [
        {
            title: '5 À 10 CRÉDITOS',
            price: '9,99',
            items: [
                ...commonDescription,
                '5 à 10 créditos'
            ],
            buttonText: 'COMPRAR POR',
            buttonLink: `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent('Olá, vim pelo Website e gostaria de comprar o pacote de 5 à 10 créditos.')}`
        },
        {
            title: '11 À 50 CRÉDITOS',
            price: '8,99',
            items: [
                ...commonDescription,
                '11 à 50 créditos'
            ],
            buttonText: 'COMPRAR POR',
            buttonLink: `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent('Olá, vim pelo Website e gostaria de comprar o pacote de 11 à 50 créditos.')}`
        },
        {
            title: '51 À 100 CRÉDITOS',
            price: '7,99',
            items: [
                ...commonDescription,
                '51 à 100 créditos'
            ],
            buttonText: 'COMPRAR POR',
            buttonLink: `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent('Olá, vim pelo Website e gostaria de comprar o pacote de 51 à 100 créditos.')}`
        },
        {
            title: '101 À 300 CRÉDITOS',
            price: '6,99',
            items: [
                ...commonDescription,
                '101 à 300 créditos'
            ],
            buttonText: 'COMPRAR POR',
            buttonLink: `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent('Olá, vim pelo Website e gostaria de comprar o pacote de 101 à 300 créditos.')}`
        },
        {
            title: '301 À 500 CRÉDITOS',
            price: '5,99',
            items: [
                ...commonDescription,
                '301 à 500 créditos'
            ],
            buttonText: 'COMPRAR POR',
            buttonLink: `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent('Olá, vim pelo Website e gostaria de comprar o pacote de 301 à 500 créditos.')}`
        }
    ];
    

    return (
        <section className="cards" id='cards'>
            <h2 data-aos="fade-up" data-aos-duration="2500">conheça nossas <b>melhores ofertas</b></h2>
            <p data-aos="fade-up" data-aos-duration="1500" className="writeRun">Encontre o plano perfeito que atende às suas necessidades e objetivos. Explore nossos planos e descubra como podemos ajudá-lo a alcançar seus objetivos de forma eficaz e conveniente.</p>
            
            <p className='arrow-scroll'>
                <img src='../img/depoiments_arrow_right.png'/>
                <img src='../img/depoiments_arrow_right.png'/>
            </p>
            <div className='card-container'>
                {cardsData.map((card, index) => (
                    <div className='card-single' key={index} data-aos="fade-up-right" data-aos-duration="2500">
                        <div className='title'>
                            <div className='circle'>
                                <img src='../img/moeda_2.png' alt='Coin'></img>
                            </div>
                            <div className='have'>
                                <h2 className="writeRun">{card.title}</h2>
                            </div>
                        </div>
                        
                        <ul className='description'>
                            {card.items.map((item, index) => (
                                <li key={index}>
                                    <img src='../img/check.png' alt='Check Icon'></img>
                                    <h3 className="writeRun">{item}</h3>
                                </li>
                            ))}
                        </ul>
                        
                        <a href={card.buttonLink} target='_blank'><button>{card.buttonText} {card.price}</button></a>
                    </div>
                ))}
            </div>
            
            <b>Para recargas acima de 500, <a>clique aqui</a></b>
        </section>
    );
}

export default Cards;
